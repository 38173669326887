.rdrDefinedRangesWrapper, .rdrStaticRange{
    background: #303030;
}

.rdrStaticRange{
    color: #fff;
    border-bottom: 1px solid #424242
}

.rdrDefinedRangesWrapper{
    border-right: solid 1px #424242;
}

.rdrStaticRangeLabel:hover{
    background: #424242 !important;
}

.rdrCalendarWrapper, .rdrDateDisplayWrapper, .rdrDateDisplayItem input{
    background: #424242;
}
.rdrDayNumber span, .rdrMonthAndYearPickers select, .rdrDateDisplayItem input{
    color: #fff;
}

.rdrWeekDay, .rdrMonthName{
    color: #a1a1a1;
}
.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
    color: rgb(48 48 48);
}

/*.rdrDayPassive{*/
/*    background: #303030;*/
/*}*/

/*.rdrDayPassive:first-child{*/
/*    border-top-left-radius: 1.333em;*/
/*    border-bottom-left-radius: 1.333em;*/
/*}*/

/*.rdrDayPassive:last-child{*/
/*    border-top-right-radius: 1.333em;*/
/*    border-bottom-right-radius: 1.333em;*/
/*}*/

.rdrDayPassive .rdrDayNumber span{
    color: #c1c1c1;
}

.rdrDayToday .rdrDayNumber span:after{
    background: #fef200;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    background: #303030;
}

.rdrYearPicker select option, .rdrMonthPicker select option,
.rdrNextPrevButton{
    background: #424242;
}

.rdrNextButton i{
    border-color: #00000000#00000000 transparent rgb(255 255 255);
}

.rdrPprevButton i{
    border-color: transparent rgb(255 255 255) transparent transparent;
}

.rdrMonthAndYearPickers select{
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px !important;
}

.MuiDialogContent-root::-webkit-scrollbar {
    display: none;
}

.rdrYearPicker select::-webkit-scrollbar {
    display: none;
}

.rdrDayDisabled {
    background-color: #424242;
}

.HideDefinedRanges .rdrDefinedRangesWrapper {
    display: none;
}