$border-color: #ffffff20;
$event-border: #ffffff20;
$calendar-border: #ffffff20;
$cell-border: #ffffff20;
$btn-border: #ffffff20;
$btn-color: #fff;
$btn-bg: #ffffff40;

$out-of-range-color: #ffffff60;
$out-of-range-bg-color: #00000030;
$today-highlight-bg: #ffffff20;

@import 'react-big-calendar/lib/sass/styles';

.rbc-show-more{
  color: white !important;
  background-color: transparent !important;
}

.rbc-overlay {
  background-color: #333 !important;
  border-color: transparent !important;
  top: 40% !important;
}

.rbc-overlay-header{
  border-color: transparent !important;
}